import React, { Fragment } from 'react';
import ProjectItem from './ProjectItem';



const ProjectList = ({ projects = {}, animate }) => {
  const dateSorted = Object.values(projects).sort((a, b) => {
    // Convertimos a objeto Date para comparar
    const fechaA = new Date(a.fecha);
    const fechaB = new Date(b.fecha);
    return fechaB - fechaA; // Para orden descendente
  });

  return (
    <Fragment>
      <div className="ProjectList">
        {dateSorted.map((singleProyect) => {
          const { idProyect, imagen} = singleProyect;
          let firstImagen = (imagen && imagen[0]) || null;
          const mainImagen = imagen.find(item => item.main === "1");
          if (mainImagen) {
            firstImagen = mainImagen;
          }

          return (
            <ProjectItem key={idProyect} project={singleProyect} firstImagen={firstImagen} animate={animate}  />
          )
        })}
      </div>
      <style jsx>
        {`
          .ProjectList {
            display:grid;
            width:100%;
            grid-template-columns: repeat(1, 1fr);
            max-width: 100%;
            grid-gap: 0px;
            margin-top:2rem;
            padding-bottom: var(--nav-bar-height);
          }      

          @media screen and (min-width:460px) {
            .ProjectList{
              grid-template-columns: repeat(1, 1fr);
            }
          }
          @media screen and (min-width:600px) {
            .ProjectList{
              grid-template-columns: repeat(2, 1fr);
            }
          }
          @media screen and (min-width:750px) {
            .ProjectList{
              grid-template-columns: repeat(3, 1fr);
            }
          }
          @media screen and (min-width:1665px) {
            .ProjectList{
              grid-template-columns: repeat(4, 1fr);
            }
          }
          
          
      `}
      </style>
    </Fragment>
  )

}



export default ProjectList;
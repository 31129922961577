import React from 'react';

const Logo = () => {

    return (
      <>
        <video disableRemotePlayback className="Logo"  autoPlay muted loop>
          <source src="/animations/vich.webm" type="video/webm" />
          <source src="/animations/vicv.mp4" type="video/mp4" />
        </video>
        <style jsx>
        {`
          .Logo {
            height: 2.50rem;
            width:auto;
          }
        `}
        </style>
      </>
    )
}

export default Logo;

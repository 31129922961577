import React, { Component } from 'react'
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Image from 'next/image';

const AnimationV = dynamic(() => import('../AnimationV'));

class ProjectItem extends Component {
  constructor(props){
    super(props);
    this.state = {
      hoverLoaded: false
    }
  }

  loadHover = () => {
    this.setState({
      hoverLoaded: true,
    })
  }


  render(){
    const { hoverLoaded } = this.state;
    const { firstImagen, project, animate } = this.props;
    const { idProyect, nombre, tipoproyecto, nameUrl, random } = project;
    return (
      <Link 
        key={`${idProyect}`}
        href={{
          pathname: `/proyecto/[idProject]/[name]`,
          query: {
            idProject: idProyect,
            name: nameUrl,
          },
        }} passHref>
          <div className={`ProjectItem ProjectItem-${idProyect} ${animate ? ' scrollAnimate':''}`} onMouseEnter={this.loadHover}>
          {firstImagen && firstImagen.srcWebp && (
            <Image
              priority
              unoptimized
              fill
              placeholder="empty" 
              className="ProjectThumb" 
              src={`https://www.vicdesign.es/admin/${firstImagen.srcWebp}`}
              alt="thumb"
            />
          )}
          <div className='foreground-layer' />
          <div className="InfoPlaceHolder animate">
            <div className='text-content'>
              {nombre && (<p className="name regular" dangerouslySetInnerHTML={{__html: nombre}} />)}
              {tipoproyecto && (<p className="kind bold" dangerouslySetInnerHTML={{__html: `DISEÑO DE ${tipoproyecto}`}}></p>)}
            </div>
            {hoverLoaded && (
              <div className='icon-content'>
                <AnimationV random={random} />
              </div>
            )}
          </div>
        </div>
        <style jsx>{`
        @keyframes temporalFade{
          0%{opacity:0;}
          12%{opacity:1}
          92%{opacity:1}
          100%{opacity:0;}
        }
        .ProjectItem{
          position:relative;
          width:100%;
          aspect-ratio: var(--picture-aspect-ratio);
          padding-top:0px;
          display:flex;
          flex-direction:column;
          justify-content:space-around;
          background-color:black;
          cursor:pointer;
          .ProjectThumb{
            z-index:-5;
          }

          .InfoPlaceHolder {
              position:absolute;
              top:50%;
              left:50%;
              transform:translate(-50%, -50%);
              background-color: white;
              width: calc(100% - 4rem);
              padding:1.5rem;
              text-transform: uppercase;
              opacity:0;
              display: flex;
              flex-direction: row;
              align-items: stretch;
              flex-wrap: wrap;
              z-index:1;
              .text-content{
                width: 80%
              }
              .icon-content{
                width:20%
              }
          }
          .foreground-layer{
              position:absolute;
              top:0px;
              left:0px;
              width:100%;
              opacity:0;
              height:100%;
              background-color:rgba(0,0,0, 0.25);
              transition:opacity 0.3s;
          }
          &:hover{
            .InfoPlaceHolder.animate {
              animation:2.3s forwards temporalFade;
            } 
            .foreground-layer{
              animation:2.3s forwards temporalFade;
              transition:opacity 0.3s;
            }
          }
        }

        @media screen and (max-width:950px){
            .ProjectItem:hover{
              .InfoPlaceHolder.animate{
                animation: none;
              }
              .foreground-layer{
                animation: none;
              }
            } 
          }  

          
        `}</style>
      </Link>
    )
  }
}

export default ProjectItem;
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router';
import Header from './header';
import Footer from './footer';
import NProgress from 'nprogress';

const Intro = dynamic(() => import('./intro'))


const Layout = ({ 
  currentPathName='/',
  insideContainer=true, 
  contentCentered=false, 
  intro=true, 
  noPaddingBottom=false,
  children,
  legals
}) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      NProgress.start();  
    };

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', () => { NProgress.done(); })

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    }
  }, [])

  return (
    <>
      {/* Esto no lo deberia de meter layout o si.. dependiendo */}
      <Header currentPathName={currentPathName} />
      <div className={`LayoutContainer${contentCentered ? ' centered' : ''}${noPaddingBottom ? ' noPaddingBottom': ''}`}>
        { intro && (<Intro />)}
        <main className={insideContainer ? 'container' : ''}>
          {children}
        </main>
      <Footer legals={legals} />
      </div>
      <style jsx>{`
        .LayoutContainer{
          padding-top: 1rem;
          padding-bottom:var(--nav-bar-height);
          --padding:2rem;
        }
        .LayoutContainer.noPaddingBottom{
          padding-bottom:0px;
        }
        .LayoutContainer.centered{
            display:flex;
            flex-direction:column;
            justify-content:space-around;
            min-height:calc(100vh - var(--nav-bar-height))
          }
      `}</style>
    </>

  )
}

export default Layout;

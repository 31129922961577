import React from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import Logo from '../logo';

const Header = ({ currentPathName }) => {
  const headerOptionSelected = useSelector(state => state.main.headerOptionSelected);
  const projectKinds = useSelector(state => state.main.projectKinds)
  
  const isContactaSelected = currentPathName.indexOf('contacta') !== -1;
  return (
    <div className="Header">
      <div className="container headerContainer">
        <div className="logoGap">
          <Link title="todos" className="option" key="Home" href="/">
              <Logo />
          </Link>
        </div>
        <div className="options">
          {projectKinds.map(({key, id, label, link}) => {
            const optionSelected = currentPathName.indexOf(label.toLowerCase()) !== -1;
            return (
              <Link title={label} key={key} href={link}>
                <span className={`regular option ${id} ${optionSelected ? ` selected` : ''}`}>
                  {label}
                </span>
              </Link>
            )
          })}
          <Link title="contacta" key={"contacta"} href="/contacta">
            <span className={`regular option contacta ${isContactaSelected ? ` selected` : ''}`}>
              CONTACTA
            </span>
          </Link>
        </div>
      </div>
      <style jsx>{`
          .Header{
            position:fixed;
            bottom:0px;
            left:0px;
            width:100%;
            height:var(--nav-bar-height);
            background-color:var(--bg-secondary);
            -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.3);
            -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.3);
            box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.3);
            display:flex;
            flex-direction:column;
            justify-content:space-around;
            z-index:4;           
          }
          .Header .headerContainer{
            display:flex;
            justify-content:space-between;
            flex-direction:row;
            overflow:visible;
            padding:0px var(--padding-container);
          }
          .Header .headerContainer .options{
            display: flex;
            align-items:center;
          }
          .Header .headerContainer .options .option {
            margin-left: 1.15rem;
            font-size:1.2rem;
            padding:0.3rem;
            height:2rem;
            cursor:pointer;
            transition: all 0.5s;
            &:hover{
              color:white;
              transition: all 0.5s;
              background-color: black;
            }
          }
          .Header .headerContainer .options .option.selected {
            color:white;
            background-color: black;
          }
          .Header .headerContainer .options .option.contacta{
            font-size: 0.75rem;
          }
          .Header .headerContainer .options .option.play {
            font-size: 0.75rem;
            border: 1px solid black;
          }
      `}
      </style>
    </div>
  )
}


export default Header;
import React from 'react';
import { actions } from '../reducers/main';
import { useDispatch } from 'react-redux';


const FilterButtons = ({ category }) => {
  const dispatch = useDispatch();

  const handleRequest = (evt) => {
    dispatch(actions.getRelatedProject({query: evt.target.value, category}))
  }

  return(
    <div className="container">
      <div className="ButtonContainer regular">
        <form className="form-searcher" id={`form-searcher`} onSubmit={handleRequest}>
          <div className='inputGap'>
            <img className="search-icon" src="/svg/search-1.svg" alt="lupa" />
            <input 
              id="searcher"
              className="regular"
              onChange={handleRequest}
              placeholder="Busca en VICDESIGN..."
              onClick={() => { handleRequest({ target: { value: '' } }) }}
            />
          </div>
        </form>
      </div>
      <style jsx>
        {`
        .ButtonContainer {
            display:flex;
            width:100%;
            max-width:650px;
            flex-direction: row;
            flex-wrap:wrap;
            justify-content:space-around;
            margin:0 auto;
            padding:2rem 2.5rem;
            
          }
          .ButtonContainer .form-searcher {
            width:100%
          }

          .ButtonContainer .form-searcher .inputGap {
            position:relative;
            max-width:420px;
            width:100%;
            margin: 0 auto;
            width:90%;
            .search-icon{
              position:absolute;
              top:50%;
              left:15px;
              transform:translate(-50%, -50%);
              height:1.25rem;
              width:auto;
            }
            #searcher {
              border:2px solid black;
              background-color:white;
              color:black;
              padding:5px;
              width:100%;
              height: auto;
              border-radius: 15px;     
              padding-left:30px;      
              margin: 0 auto;
              display:block;
            }
          }
        `}
      </style>
    </div>
  )
}


export default FilterButtons;

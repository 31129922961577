import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { END } from 'redux-saga'
import { wrapper } from '../store'
import Seo from '../components/Seo';
import Layout from '../components/Layout'
import ProjectList from '../components/projectList';
import FilterButtons from '../components/filterButtons';
import { fetchProjects2, fetchKinds2, fetchLegals } from '../sagas/main'
import { actions as mainActions } from '../reducers/main';

let animate = true;

const Index = ({ category='all', legals }) => {
  const dispatch = useDispatch();
  const projectKindSelected = useSelector(state => state.main.projectKindSelected);
  let projects = useSelector(state => state.main.projects2[category]);
  const filtered = useSelector(state => state.main.projects2.filtered);
  if (filtered) {
    projects = filtered;
    animate = false;
  }
  const currentSeo = useSelector(state => state.main.currentSeo);
    
  const handleFilter = (kind) => {
    dispatch(mainActions.setProjectsKind(kind))
  }
  

  return (
    <>
      <Seo {...currentSeo} url={`/${category !== 'all' ? category : ''}`} />
      <Layout legals={legals} insideContainer={false} currentPathName={`/${category}`}>
        <div className="homeAnimation">
          <div className="container">
            <video disableRemotePlayback className="Comecoco" autoPlay muted loop>
              <source src="/animations/comecoco.webm" type="video/webm" />
              <source src="/animations/comecoco.mp4" type="video/mp4" />
            </video>
            <video disableRemotePlayback className="FantasmaHuye" autoPlay muted loop>
              <source src="/animations/fantasmahuye.webm" type="video/webm" />
              <source src="/animations/fantasmahuye.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <FilterButtons category={category} filterSelected={projectKindSelected} onClick={handleFilter} />
        {projects && (<ProjectList animate={animate} projects={projects} />)}
      </Layout>
      <style jsx>{`
        .homeAnimation{
          display:flex;
          width: 100%;
          padding:2rem var(--padding-container);
          height:auto;
          align-items:center;
        }
        .homeAnimation .FantasmaHuye{
            height:3.18rem;
            width:auto;
          }
          .homeAnimation  .Comecoco{
            height:5.75rem;
            width:auto;
            margin-right:2rem;
          }
      `}
      </style>
    </>
  )
}

// export const getStaticPaths = async () => {
//   let paths = await fetchKinds2();
//   paths = paths.map(({nombre}) => {
//     return `/${nombre.toLowerCase()}`;
//   })
//   return {
//     paths,
//     fallback: false,
//   };
// }


export const myGetStaticProps = async(data) => {
  const { store, params, seoReq } = data;
  let category = ''
  if (params && params.category && params.category !== 'home') {
    ({ category } = params);
  }
  // store.dispatch();

  const [allProjects, categoryProjects, legals] = await Promise.all([
    fetchProjects2(null, true),
    fetchProjects2(category, true),
    fetchLegals(),
  ]);

  // console.log({
  //   allProjects: allProjects.length,
  //   categoryProjects: categoryProjects.length,
  //   category,
  // })

  store.dispatch(mainActions.getCurrentSeo(seoReq))
  store.dispatch(mainActions.setProjects('all', allProjects))
  store.dispatch(mainActions.setProjects(category.toLowerCase(), categoryProjects))
  store.dispatch(mainActions.getProjectsKind());
  
  store.dispatch(END);
  await store.sagaTask.toPromise()
  return {
    props: {
      category,
      legals:legals[0],
    },
    // revalidate: 360
  }
}

export const getServerSideProps = wrapper.getServerSideProps(async (data) => {
  const { params } = data;
  const { category } = params;
  
  return myGetStaticProps({
    ...data,
    seoReq: {
      page: category,
      entity: null,
      idName: null,
    }
  });
});



export default Index
 
import React from 'react';
import dynamic from 'next/dynamic'
import { useDispatch } from 'react-redux'
import { actions as modalActions } from '@/reducers/modals-reducer';

const TextModal = dynamic(() => import('./text-modal'))

const Footer = ({legals}) => {
  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(
      modalActions.addModal({
        id: "legal-modal",
        Element2Show: TextModal,
        modalProps: {
          contentPosition: "center--center",
          opacityLayoutColor: "black",
          inSceneEffect: "fadeIn",
          contentInsideContainer: false,
          outSceneEffect: "fadeOut",
          closeByClickingOutside: true,
          scrollAllowed: false,
          createAnchor: false,
        },
        elementProps: { 
          title: legals.nombre,
          contenido: legals.contenido,
          closeModal:() => dispatch(modalActions.addModal({id:''}))
        },
      })
    );   
  }

  return(
    <div className='Footer'>
      <div className='options-gap'>
        <div className='option' onClick={openModal}>{legals.nombre} </div>
      </div>
      <style jsx>{`
      .Footer {
        width:100%;
        height:auto;
        padding:2rem;
        color:white;
        background-color:black;
        .options-gap {
          display:flex;
          justify-content:space-around;
          align-item:center;
          .option {
            cursor:pointer;
          }
        }
      }
        
        
      `}</style>
    </div>
  )
}



export default Footer;